import { StaticImage } from 'gatsby-plugin-image'
import React from 'react'
import { Divider, Wrapper } from './styles'

const Card = () => {
  return (
    <>
      <Wrapper>
        <StaticImage src={'../../../images/package.jpg'} alt="Auto Transwell" />
        <div className="content">
          <h4>Co to jest transport specjalny?</h4>
          <p>
            W skrócie pisząc, transport specjalny to taki, który obejmuje
            przewóz przedmiotów nietypowych lub takich, których przewożenie
            wymaga specjalnych warunków oraz usługi transportu
            specjalistycznego, którego wykonanie obejmuje konkretny czas. Coraz
            częściej firmy potrzebują usług transportowych na ten sam dzień.
            Tradycyjne usługi kurierskie są stosunkowo tanie, jednak nigdy nie
            są pewne. W wielu przypadkach dochodzi do sytuacji, w których
            potrzebny jest transport dokumentów, ważnych elementów czy
            zapomnianej prezentacji na konferencje. Dowóz każdej z tych rzeczy
            nie może być wykonany jutro czy za dwa dni. Musi być dzisiaj,
            najszybciej, jak to możliwe. W wielu przedsiębiorstwach brak
            odpowiedniego środka transportu, który jest wolny lub osoby, która
            może rzucić dotychczasowe zajęcie, żeby wsiąść za kółko i zawieźć
            ładunek czy przesyłkę. Często się zdarza, że zwyczajnie firma nie ma
            środków ludzkich, czasowych czy zwyczajnie brakuje wolnego samochodu
            do przewozu ładunku. W takich momentach również można skorzystać z
            transportu specjalistycznego.
          </p>
          <p>
            Transport drogowy z dostawą na dziś to jedna z wielu usług naszej
            firma. Transwell za wszelką cenę stara się sprostać państwa
            potrzebom. Nasza oferta najczęściej jest bardzo konkurencyjna i w
            wielu przypadkach, z ekonomicznego punktu widzenia, klienci
            wybierają naszą usługę, zamiast sami brać się za przewóz. Zwyczajnie
            zaoszczędzony czas i niewielki wydatek warte są tego, aby zaufać
            naszej firmie.
          </p>
        </div>
      </Wrapper>
      <Divider />
      <Wrapper noMargin>
        <div className="content">
          <h4>Transport specjalistyczny Transwell</h4>
          <p>
            Rynek transportowy jest trudny. Jest w nim duża konkurencja i trudno
            znaleźć jeszcze niezagospodarowaną lukę w tej branży. Nie wykonujemy
            tradycyjnych transportów, nie jesteśmy kurierami ani nie korzystamy
            z giełdy transportowej. Transwell zajmuje się transportem
            specjalnym. Jesteśmy gotowi na przewóz ładunków nietypowych. Jeśli
            potrzebujesz firmy, która zagwarantuje przewóz towarów między
            zakładami, z jednego miasta do drugiego lub masz zadanie, ale żadna
            firma nie chce się go podjąć, koniecznie do nas zadzwoń.
          </p>
          <p>
            Posiadamy flotę samochodów z ładownością do 3,5 tony. W naszej
            firmie mamy busy przystosowane do transportu wielu rodzajów towarów.
            Zajmujemy się transportem wewnętrznym w wielu firmach, prowadzimy
            przewóz ładunków dla szpitali i aptek. Obsługujemy firmy, które
            wymagają szybkiej dostawy dokumentów na swoje inwestycje budowlane.
            Zapewniamy odbiór i dowóz jeszcze tego samego dnia. Jeśli
            potrzebujesz zaufanej firmy, która daje gwarancję na swoje usługi,
            koniecznie do nas zadzwoń, a na pewno się nie zawiedziesz!
          </p>
        </div>
        <div className="images">
          <StaticImage
            src={'../../../images/transwell_kato.jpg'}
            alt="Auto Transwell"
          />
          <StaticImage
            src={'../../../images/transwell_unpacking.jpg'}
            alt="Auto Transwell"
          />
        </div>
      </Wrapper>
    </>
  )
}

export default Card
