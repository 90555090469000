/* eslint-disable react/jsx-pascal-case */

import React from 'react'
import Layout from 'src/hoc/Layout'
import Nav from 'components/Nav'
import SubPageHeader from 'components/SubPageHeader'

import SpecialTransportPage from 'components/SpecialTransportPage'
import SEO from 'components/SEO'

const SpecialTransport = props => {
  return (
    <Layout>
      <SEO />
      <Nav {...props} black />
      <SubPageHeader title="Transport dedykowany" imageName="FLEET" />
      <SpecialTransportPage />
    </Layout>
  )
}

export default SpecialTransport
