import React from 'react'
import Article from '../Article'
import { Wrapper } from './styles'
import Card from './Card'

const ContractLogisticPage = () => {
  return (
    <Article>
      <Wrapper>
        <p className="introduction">
          Transport specjalny określa usługi przewiezienia nietypowych paczek
          czy ładunków, czyli innych od tych, którymi na co dzień zajmują się
          firmy kurierskie. Wyróżnia się kilka podstawowych transportów
          specjalistycznych. Najpowszechniejsze są tak zwane DGR-y, czyli
          transport towarów niebezpiecznych. Ponad to transport wszystkich
          towarów chłodzonych również określany jest jako specjalny, wymagający
          odpowiedniego samochodu wyposażonego w chłodnie. Rodzajami transportu
          nietypowego są także wszystkie przesyłki ponadgabarytowe oraz
          transport pojazdów. W ostatnim czasie pod ten rodzaj transportu
          przypisuje się również czartery i przewóz osób.
        </p>
        <p className="introduction">
          Specjalistyczny transport określa wiele gałęzi tego zawodu, jednak
          powyższy opis nie wyczerpuje tematu w całości. Przewóz leków oraz
          przewozy sanitarne są kolejnymi gałęziami transportu, które można
          podpiąć pod metkę „transport specjalny”.
        </p>
        <h3>
          Dostępność i gotowość 24h/365 dni w roku
          <br />
          <br />
          Zaangażowanie i indywidualne podejście do każdego klienta
        </h3>
        <p className="common">
          Oferujemy możliwość stałej współpracy z atrakcyjnymi rabatami dla
          firm.
        </p>
        <Card />
      </Wrapper>
    </Article>
  )
}

export default ContractLogisticPage
